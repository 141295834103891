import { dataGridEditorCellControlKey } from 'o365.modules.vue.injectionKeys.js';
import { addEventListener } from 'o365.vue.composables.EventListener.ts';
import { inject, provide, onMounted } from 'vue';

/**
 * When the component this composable is used in gets rendered inside a grid editor, 
 * instead of the inline editor a popup one will be used.
 */
export default function useDataGridPopupEditor(pOptions?: {
    /** When provided grid */
    focusContainer?: Ref<HTMLElement>
}) {
    const isGridEditor = inject('is-in-grid-cell', false);
    const editorApi = inject(dataGridEditorCellControlKey, null);
    if (editorApi == null) { return { isGridEditor }; }
    provide(dataGridEditorCellControlKey, null); //Clear the grid editor scope for children of this component
    editorApi.activatePopupMode();
    if (pOptions?.focusContainer) {
        onMounted(() => {
            if (pOptions?.focusContainer?.value) {
                addEventListener(pOptions.focusContainer.value, 'keydown', (pEvent) => {
                    console.log('l')
                    if (pEvent.key === 'Tab') {
                        const container = pOptions!.focusContainer!.value!;
                        const focusableElements = container.querySelectorAll("input, select, textarea, button, a[href], area[href], iframe, object, embed, *[tabindex], *[contenteditable]");
                        const focusableElementsArray = Array.prototype.slice.call(focusableElements);
                        if (pEvent.shiftKey) {
                            if (document.activeElement !== focusableElementsArray[0]) {
                                pEvent.stopPropagation();
                            }
                        } else {
                            if (document.activeElement !== focusableElementsArray[focusableElementsArray.length - 1]) {
                                pEvent.stopPropagation();
                            }
                        }
                    }
                });
            }
        });
    }

    return { isGridEditor };
}

type Ref<T> = { value: T | undefined };